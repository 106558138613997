.banner-back {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-top: -11.9%;
  background-image: url('../../build/img/banner-saida/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  top: 183px;
    .banner-close {
      right: 3%;
      top: 3%;
    }
}

.banner-back-mob {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30%;
  z-index: 1000;
  margin-top: -72%;
  background-image: url('../../build/img/banner-saida/mob.webp');
  background-repeat: no-repeat;
  background-size: cover;
  top: 259px;
    .banner-close-mob {
      right: 3%;
      top: 3%;
    }

    .banner-card-mob {
      top: 80%;
    }
}

.banner-back-mob-div {
  background-color: #EADCC7;
  width: 100%;
  height: 70%;
  z-index: 11;
  top: 265px;
}